/** @format */

import { Offer } from '@/types/offers';
import { FunctionComponent as FC, useContext, useMemo } from 'react';
import { P } from '../ui/Typography';

import { i18nContext } from '@/context/i18nContext';
import { Entry } from 'contentful';
import styled from 'styled-components';
import { breakpointFrom, breakpointUpTo } from '../../theme/mixins';
import { ExternalLink } from '../../types/page';
import { StyledButton } from '../ui/Button';

export const JobPosition: FC<Props> = ({ offer }) => {
  const translate = useContext(i18nContext);
  const itemLink = useMemo(() => {
    return {
      fields: { label: translate['work-with-us'].apply, url: offer.url },
    } as Entry<ExternalLink['fields']>;
  }, [offer]);

  return (
    <i18nContext.Consumer>
      {t => (
        <Offer>
          <Title as="h4">{offer.title.toLowerCase()}</Title>
          <Chip as="span">{t['work-with-us'][offer.employment_type ?? '']}</Chip>
          <Chip as="span">{offer.location.city}</Chip>
          <StyledButton item={itemLink} position="right" variant="secondaryWhite" elementType="magic">
            {t['work-with-us'].apply}
          </StyledButton>
        </Offer>
      )}
    </i18nContext.Consumer>
  );
};

type Props = {
  offer: Offer;
};

const Offer = styled.div`
  padding-block: 1rem;
  border-bottom: 1px solid #a6b8c4;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  h4 {
    grid-column: 1/-1;
  }

  & > * + * {
    margin-top: 1em;
  }

  ${breakpointUpTo.xl`
    column-gap: 1rem;

    a {
      grid-column: 1/-1;
      justify-self: end;
    }
  `}

  ${breakpointFrom.xl`
    gap: clamp(4rem, -1.0000rem + 6.6667vw, 5rem);
    grid-template-columns: clamp(25rem, -20.0000rem + 60.0000vw, 30rem) 1fr 1fr auto;

    h4,
    span:first-of-type {
      grid-column: auto;
    }

    a {
      justify-self: end;
    }

    & > * + * {
      margin-top: 0;
    }

  `}
`;

const Title = styled(P)`
  font-weight: 700;
  color: ${({ theme }) => theme.color.white};
  text-transform: capitalize;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const Chip = styled(P)`
  ${breakpointFrom.xl`
    text-align: center;
    `}

  display: block;
  color: ${({ theme }) => theme.color.teal.dark};
  background: ${({ theme }) => theme.color.yellow};
  max-width: max-content;
  padding-inline: 1rem;
  border-radius: 1.25rem;
  text-transform: capitalize;
`;
