/** @format */

import { i18nContext } from '@/context/i18nContext';
import { FunctionComponent as FC, useContext } from 'react';
import { styled } from 'styled-components';
import { Subtitle } from '../ui/Typography';

export const CourtesyMessage: FC<Props> = ({ type }) => {
  const t = useContext(i18nContext);
  return (
    <Container>
      <Subtitle as="p">{t['work-with-us'][type]}</Subtitle>
    </Container>
  );
};

const Container = styled.div`
  p {
    white-space: pre-line;
    color: ${({ theme }) => theme.color.yellow};
  }
`;

type Props = {
  type: 'api-error' | 'no-results' | 'no-filtered';
};
