/** @format */

import { Container, ContainerWrapper } from '@/components/ui/Layout';
import { Loader } from '@/components/ui/Loader';
import { RichText } from '@/components/ui/RichText';
import { i18nContext } from '@/context/i18nContext';
import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { getAllOffers } from '@/lib/ats.api';
import { Offers } from '@/types/offers';
import { InternalLink } from '@/types/page';
import { FindColor } from '@/utils/findThemeColor';
import { MagicLink } from '@/utils/magicLink';
import { filterOffersByTag, offers_tags } from '@/utils/offers';
import { Document } from '@contentful/rich-text-types';
import { useQuery } from '@tanstack/react-query';
import { Entry } from 'contentful';
import { FunctionComponent as FC, useContext, useEffect, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { CommonButton } from '../ui/Button/CommonButton';
import { CourtesyMessage } from './CourtesyMessage';
import { JobPosition } from './JobPosition';

import dynamic from 'next/dynamic';

const SearchJobsMobile = dynamic(() => import('./SearchJobsMobile').then(mod => mod.SearchJobsMobile), {
  loading: () => <Loader />,
});
const SearchJobsDesktop = dynamic(() => import('./SearchJobsDesktop').then(mod => mod.SearchJobsDesktop), {
  loading: () => <Loader />,
});

export const JobsPositionsSection: FC<Props> = ({ title, background, link }) => {
  const theme = useContext(ThemeContext);
  const translations = useContext(i18nContext);

  const mediaQueryIndex = useMediaQueryIndex();

  const mobile = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex < 2), [mediaQueryIndex]);
  const desktop = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex >= 2), [mediaQueryIndex]);

  const offersQuery = useQuery({
    queryKey: ['ats', 'offers'],
    queryFn: getAllOffers,
  });

  const [reset, setReset] = useState<boolean>(false);
  const [filteredJobs, setFilteredJobs] = useState<Offers>([...(offersQuery.data ?? [])]);

  const uniqueLocations = useMemo(
    () => Array.from(new Set(offersQuery.data?.map(item => item.location))),
    [offersQuery],
  );

  const tagHasOffers = (tag: string) => filteredJobs.filter(job => job.offer_tags.includes(tag)).length > 0;

  useEffect(() => {
    offersQuery.isSuccess && setFilteredJobs([...offersQuery.data]);
  }, [offersQuery.isFetched]);

  return (
    <ContainerWrapper as="section" $color={FindColor(background ?? 'white', theme?.color)} id="join-the-team">
      <StyledContainer>
        <RichText text={title} />

        <div>
          {offersQuery.isError && <CourtesyMessage type="api-error" />}
          {offersQuery.isLoading && <Loader light />}
          {offersQuery.isSuccess && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {mobile && (
                <SearchJobsMobile
                  locations={uniqueLocations}
                  allJobs={offersQuery.data}
                  setFilteredJobs={setFilteredJobs}
                  resetState={[reset, setReset]}
                />
              )}
              {desktop && (
                <SearchJobsDesktop
                  locations={uniqueLocations}
                  allJobs={offersQuery.data}
                  setFilteredJobs={setFilteredJobs}
                  resetState={[reset, setReset]}
                />
              )}
              <StyledLink item={link} />
            </div>
          )}
          {offersQuery.isSuccess && filteredJobs.length > 0 && (
            <>
              {offers_tags.map(tag => (
                <>
                  {filterOffersByTag(filteredJobs, tag)?.map(offer => (
                    <JobPosition key={`${offer.title}-${offer.department}`} offer={offer} />
                  ))}
                </>
              ))}
            </>
          )}
          {offersQuery.isSuccess && filteredJobs?.length === 0 && offersQuery.data?.length > 0 && (
            <>
              <CourtesyMessage type="no-filtered" />
              <CommonButton $variant="hollow" onClick={() => setReset(true)} style={{ marginTop: '2em' }}>
                reset
              </CommonButton>
            </>
          )}
          {offersQuery.isSuccess && offersQuery.data?.length === 0 && <CourtesyMessage type="no-results" />}
        </div>
      </StyledContainer>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
  background?: string;
  link: Entry<InternalLink['fields']>;
};

const StyledLink = styled(MagicLink)`
  font-size: ${({ theme }) => theme.font.size['0.9375']};
  font-weight: 500;

  color: ${({ theme }) => theme.color.gray.light};

  &:hover {
    color: ${({ theme }) => theme.color.pink};
  }
`;

const StyledContainer = styled(Container)`
  padding-block: clamp(3.75rem, 3rem + 3.3333vw, 6rem);

  h2 {
    color: ${({ theme }) => theme.color.white};
  }

  h2 + div {
    margin-top: clamp(1.88rem, 1.0067rem + 3.8815vw, 4.5rem);
  }

  h3 {
    padding-bottom: 1rem;
    border-bottom: 1px solid #a6b8c4;
  }

  h3:not(:first-of-type) {
    margin-top: 3.75rem;
  }
`;
