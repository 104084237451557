/** @format */

import { OfferSearchData, Offers } from '@/types/offers';
import { isSimilar } from './searchSimilarString';

export const offers_tags = ['dovevivo', 'campus', 'altido', 'cheznestor', 'open', 'rinnow'];

export const filterOffersByTag = (offers: Offers, tag: string): Offers =>
  offers.filter(offer => offer.offer_tags.includes(tag));

export const filterOffersByTitle = (offers: Offers, title: string): Offers => {
  return offers.filter(offer => {
    const offerTitle = offer.title;

    const similar = offerTitle
      .split(' ')
      .map(t => isSimilar(t.toLowerCase(), title.toLowerCase()))
      .includes(true);
    return offer.title.toLowerCase().includes(title) || similar;
  });
};

export const filterOffersByCountry = (offers: Offers, country: string) =>
  offers.filter(offer => offer.location.country && offer.location.country.toLowerCase().includes(country));

export const filterOffersByCity = (offers: Offers, city: string) =>
  offers.filter(offer => offer.location.city.toLowerCase().includes(city));

export const search = (data: OfferSearchData, jobs: Offers) => {
  let filtered = [...jobs];

  if (data.title) {
    filtered = filterOffersByTitle(filtered, data.title.toLowerCase());
  }

  if (data.country) {
    filtered = filterOffersByCountry(filtered, data.country.toLowerCase());
  }
  if (data.city) {
    filtered = filterOffersByCity(filtered, data.city.toLowerCase());
  }

  return filtered;
};
