/** @format */

import styled from 'styled-components';

type Props = {
  $variant: 'filled' | 'hollow';
};

export const CommonButton = styled.button<Props>`
  background: ${({ theme, $variant }) => ($variant === 'filled' ? theme.color.yellow : 'none')};
  border: 1px solid ${({ theme, $variant }) => ($variant === 'hollow' ? theme.color.yellow : 'transparent')};
  border-radius: 0.625rem;
  padding: 0.75rem 0.9375rem;
  margin-bottom: 2em;

  color: ${({ theme, $variant }) => ($variant === 'filled' ? theme.color.teal.dark : theme.color.yellow)};
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size['0.9375']};
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-transform: uppercase;

  transition: all 0.3s cubic-bezier(0.36, 0.33, 0, 1);

  &:active,
  &:hover,
  &:focus-visible {
    background: ${({ theme }) => theme.color.teal.light};
    color: ${({ theme }) => theme.color.white};
    border: none;
  }
`;
