/** @format */

import { OfferResponse, Offers } from '@/types/offers';
import axios, { AxiosResponse } from 'axios';

const KEY =
  process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? '94e30719690c4583aba0f4752acb86d7' : 'b97ed49f7ffe4ba28e0a64be8d2ef778';
const base_url =
  process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? 'https://api.joivy.com/joivy' : 'https://api-dev.joivy.com';
const offers_endpoint = '/get-job-offers';

const headers = {
  'Ocp-Apim-Subscription-Key': KEY,
};

export const getAllOffers = async (): Promise<Offers> => {
  const response: AxiosResponse<OfferResponse[]> = await axios.get(`${base_url}${offers_endpoint}`, {
    headers,
  });

  const data = response.data.map(offer => {
    return {
      ...offer,
      location: { country: offer.location.split(',')[0], city: offer.location.split(',')[2] },
    };
  });

  return data;
};
